<template>
  <div class="submission-discussion">
    <h4>Discussion</h4>
    <v-list v-if="replies.length" class="pt-0">
      <v-list-item class="px-2 pr-0" v-for="reply in replies" :key="reply.id">
        <v-list-item-content>
          <v-list-item-subtitle>
            {{ reply.user.full_name }}
          </v-list-item-subtitle>
          <v-list-item-title class="text-wrap">{{ reply.reply }}</v-list-item-title>
          <v-list-item-subtitle class="text-caption">
            {{ moment(reply.created).format('lll') }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <intract-drop-down-menu
          v-if="reply.user.id == currentUser.id"
          :options-list="getDropdownOptions(reply)"
        />
      </v-list-item>
    </v-list>
    <p class="mb-2" v-else>No messages found</p>
    <v-btn
      small
      block
      outlined
      color="primary"
      class="non-focused"
      @click="replySheet.visible = true"
      >Write a message</v-btn
    >
    <intract-create-edit-sheet
    persistent
      key="submission-discussion-reply-sheet"
      title="Write a Message"
      :visible="replySheet.visible"
      @close="replySheet.visible = false"
      :fields="formFields"
      :endpoint="endpoint"
      :data-object="replySheet.obj"
      :create-success-message="replySheet.createSuccessMessage"
      @objectCreated="getAllReplies"
      @updateObject="(obj) => (replySheet.obj = obj)"
    />
    <confirmation-dialog
      title="Are you sure you want to delete this message?"
      description="This action cannot be undone"
      :visible="showDeleteDialog"
      delete-dialog
      @successCallback="deleteReply"
      @failureCallback="showDeleteDialog = false"
    />
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";
import moment from 'moment';
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import ConfirmationDialog from '@components/dialogs/ConfirmationDialog';
export default {
  name: "SubmissionDiscussion",
  mixins: [Mixins.essentials],
  props: {
    endpoint: {
      type: String,
      default: null
    },
    submissionId: {
      type: Number,
      default: null,
    },
  },
  components: {
    IntractCreateEditSheet,
    IntractDropDownMenu,
    ConfirmationDialog,
  },

  data() {
    return {
      replies: [],
      moment: moment,

      toBeDeletedReply: null,
      showDeleteDialog :false,

      replySheet: {
        visible: false,
        createSuccessMessage: "Message has been successfully sent!",
        editId: null,
        obj: {
          submission: this.submissionId,
          user: null,
        },
      },
    };
  },
  computed: {


    formFields() {
      return {
        reply: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Message",
          required: true,
          md: 12,
          max: 255,
        },
      };
    },
  },
  methods: {
    getDropdownOptions(reply) {
      var self = this;
      return [
        {
          obj: reply,
          value: 1,
          text: "Delete",
          icon: "mdi-delete",
          type: "delete",
          action() {
            self.toBeDeletedReply = reply;
            self.showDeleteDialog = true;
          }
        },
      ];
    },

    async getAllReplies() {
      var url = this.Helper.addUrlParams(
        this.endpoint,
        "submission_id=" + this.submissionId
      );
      this.replies = await this.api.call(this.essentials, url);
    },

    async deleteReply() {
      var url = this.endpoint + this.toBeDeletedReply.id + "/";
      this.toBeDeletedReply = null;
      this.showDeleteDialog = false;
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.getAllReplies();
        this.showSnackbar({
          title: "Message has been successfully deleted!",
          type: "success",
        });
      }
    },
  },
  created() {
    // console.log("test");
    this.replySheet.obj.user = this.currentUser.id;
    this.getAllReplies();
  },
};
</script>